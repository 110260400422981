<template>
  <VList v-if="documents.length">
    <VListItem
      v-for="document in documents"
      :key="document.id"
      :href="`${document.uri}`"
      target="_blank"
      rel="noopener noreferrer"
      :title="document.title"
    >
      <VListItemAvatar
        class="align-self-start mt-4"
        tile
        :size="24"
      >
        <VImg
          contain
          :src="`/${document.thumbnail}`"
        />
      </VListItemAvatar>
      <VListItemContent>
        <VListItemTitle>{{ document.title }}</VListItemTitle>
        <VListItemSubtitle>
          <span v-if="document.size">{{ formatSize(document.size) }}</span>
        </VListItemSubtitle>
        <div
          v-if="document.description"
          class="body-2"
        >
          {{ document.description }}
        </div>
      </VListItemContent>
    </VListItem>
  </VList>
</template>

<script>
import { formatSize } from '@/utils/common'

export default {
  name: 'UDocuments',
  props: {
    documents: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    formatSize
  }
}
</script>
